<!--门禁模块--设备页-->
<template>
  <div id="equipment" v-loading="loading" :element-loading-text="$t('doorequipment.a12')">
    <div class="header">
      <div class="header-margin-right">
        <span>{{ `${$t("doorequipment.a1")}：` }}</span>
        <a-select v-model="form.type" style="width: 120px">
          <a-select-option v-for="tiem in typeselect" :key="tiem.value">
            {{ $t(tiem.name) }}
          </a-select-option>
        </a-select>
      </div>
      <div class="header-margin-right">
        <span>{{ `${$t("doorequipment.a2")}：` }}</span>
        <a-select v-model="form.floor" style="width: 120px">
          <a-select-option v-for="(item, index) in options" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <a-radio-group v-model="value" style="margin-right:15px">
          <a-radio-button :value="1">
            <div class="type">
              {{ $t("doorequipment.a16") }}
              <a-icon type="appstore" :style="{ fontSize: '18px', 'margin-left': '5px' }" />
            </div>
          </a-radio-button>
          <a-radio-button :value="2">
            <div class="type">
              {{ $t("doorequipment.a17") }}
              <a-icon type="profile" :style="{ fontSize: '18px', 'margin-left': '5px' }" />
            </div>
          </a-radio-button>
        </a-radio-group>
        <transition name="slide-fade" mode="out-in">
          <a-button-group v-if="value === 1">
            <a-button icon="down" @click="developChange">
              {{ $t("doorequipment.a18") }}
            </a-button>
            <a-button icon="up" @click="packUpChange">
              {{ $t("doorequipment.a19") }}
            </a-button>
          </a-button-group>
        </transition>
      </div>
    </div>
    <!-- 卡片内容 -->
    <div class="main">
      <!-- 卡片显示 -->
      <transition name="slide-fade" mode="out-in">
        <div class="main-view" v-if="value === 1">
          <a-collapse v-model="developSelect">
            <a-collapse-panel v-for="(item,index) in cardlist" :key="`${index}`" :header="item.floorName">
              <div class="card-content">
                <my-doorcard v-for="content in item.securityEquipmentInfoList" :key="content.id" :cardContent="content" @hasVideoChange="hasVideoChange"></my-doorcard>
                <div class="empty" v-for="n in 10" :key="'m' + n"></div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </transition>

      <!-- 表格显示 -->
      <transition name="slide-fade" mode="out-in">
        <div class="main-view" v-if="value === 2">
          <a-table :columns="columns" :data-source="data" :pagination="pagination" bordered size="middle">
            <template slot="operate" slot-scope="text, record">
              <a-button type="primary" ghost @click="goDetails(record)">
                {{ $t("doorequipment.a20") }}
              </a-button>
            </template>
            <template slot="switch" slot-scope="text, record">
              <a-switch v-model="record.changeState" :loading="record.switchLoading" v-if="switchShow(record)" @change="(e) => { onChange(e,record) }"/>
              <span v-else>--</span>
            </template>
            <template slot="hasVideo" slot-scope="text, record">
              <a-button v-if="record.hasVideo" type="info" icon="video-camera" shape="round" size="small" @click="getVideo(record)"></a-button>
              <span v-else>--</span>
            </template>
          </a-table>
        </div>
      </transition>

    </div>
    <!-- 底部图标说明栏 -->
    <div class="footer">
      <ul class="express">
        <li class="expressli" v-for="(item, index) in icon" :key="index">
          <img :src="item.url" />
          <span>{{ $t(item.name) }}</span>
        </li>
      </ul>
    </div>
    <!-- 视频弹框 -->
    <video-dialog :bvisible="vdialog"></video-dialog>
  </div>
</template>

<script>
import { getTenantList, getCardList,getDoorList, getEquipmentList, doorSwitch } from "../../api/door";
import doorcard from "./components/doorCard";
import videoDialog from "../monitoring/videoDialog.vue";
export default {
  name: 'equipment',
  data() {
    return {
      vdialog: false,
      camera: null,
      form: {
        floor: 0,
        type: '全部',
      },
      developSelect:[],
      packUp:[],
      value:1,
      serviceType:3,
      loading: false,
      changeState:false,
      switchLoading:false,
      options: [],
      cardlist: [],
      typeselect: [
        { name: 'doorequipment.a3', value: '全部' },
        { name: 'doorequipment.a4', value: '门' },
        { name: 'doorequipment.a5', value: '认证终端' }
      ],
      icon: [
        {
          name: 'doorequipment.a6',
          url: require('../../../public/images/door/equipment01.png'),
        },
          //change by  pzj 2023-04-20
        // {
        //   name: 'doorequipment.a7',
        //   url: require('../../../public/images/door/equipment02.png'),
        // },
          {
              name: 'doorequipment.a28',
              url: require('../../../public/images/door/equipment06.png'),
          },

        {
          name: 'doorequipment.a8',
          url: require('../../../public/images/door/equipment03.png'),
        },
          //change by  pzj 2023-04-20
        // {
        //   name: 'doorequipment.a9',
        //   url: require('../../../public/images/door/equipment04.png'),
        // },
        // {
        //   name: 'doorequipment.a10',
        //   url: require('../../../public/images/door/equipment05.png'),
        // },

      ],
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ['10', '30', '50', '100'], //每页中显示的数据
        showTotal: (total) => `${total} ${this.$t('doorequipment.a21')}`, //分页中显示总的数据
        showQuickJumper: false, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current
          this.pagination.pageSize = size
        }
      },
      columns:[
        {
          title: 'NO',
          dataIndex: 'NO',
          customRender: (text, record, index) =>{
            const obj = {
              children: `${(this.pagination.defaultCurrent - 1) * this.pagination.pageSize + index + 1}`
            }
            return obj
          },
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('doorequipment.a22'),
          dataIndex: 'floorName',
          key: 'floorName',
          customRender: (text, row, index) => {
            const obj = {
              children: text !== null ? text : "",
              attrs: {
                rowSpan: 1,
              }
            }
            obj.attrs.rowSpan = this.renderCells(text, this.data, "floorName", Number(index)+Number(this.pagination.pageSize * (this.pagination.defaultCurrent - 1)))
            return obj
          },
          align: 'center'
        },
        {
          title: this.$t('doorequipment.a23'),
          dataIndex: 'name',
          key: 'name',
          align: 'center'
        },
        {
          title: this.$t('doorequipment.a24'),
          dataIndex: 'switch',
          scopedSlots: { customRender: 'switch' },
          align: 'center'
        },
        {
          title: this.$t('doorequipment.a25'),
          dataIndex: 'hasVideo',
          key: 'hasVideo',
          scopedSlots: { customRender: 'hasVideo' },
          align: 'center'
        },
        {
          title: this.$t('doorequipment.a26'),
          key: 'state',
          dataIndex: 'state',
          align: 'center'
        },
        {
          title: this.$t('doorequipment.a27'),
          dataIndex: 'operate',
          scopedSlots: { customRender: 'operate' },
          width: 100,
          fixed: 'right',
          align: 'center'
        }
      ],
      data:[]
    }
  },
  created() {
    // 页面初始化，初始化-->楼层/租户选择列表
    this.loading = true
    this.serviceType = this.$route.query.serviceType
    getTenantList(this.$route.query.id)
    .then((res) => {
      let { data } = res
      this.options = data
      const arr = data.filter((item)=>{ if(item.tenantType === 3){ return item } })
      if(arr.length !== 0){
        this.form.floor = arr[0].id
      }else{
        this.form.floor = data[0].id
      }
    })
    .catch((err) => {
      console.log(err)
    })
  },
  watch: {
    // 监听下拉选择框，在选择后更新设备卡片
    form: {
      handler(newVal) {
        this.loading = true;
        const data = {
          clientId: this.$store.getters.clientId,
          siteId: Number(this.$route.query.id),
          tenantId: newVal.floor,
          selectedDispType: newVal.type,
          serviceType: Number(this.serviceType)
        }
        getEquipmentList(data)
        .then(res => {
          // console.log('设备数据',res)
          this.loading = false
          // 卡片数据赋值
          const { data } = res
          this.cardlist = data
          // 展开和收起选择赋值
          for(let i = 0;i < data.length;i++){
            this.developSelect.push(`${i}`)
            this.packUp.push(`${i}`)
          }
          // 表格数据赋值
          const arr = data.map((item)=>{
            return item.securityEquipmentInfoList.map((obj)=>{
                    this.$set(obj, 'floorName', item.floorName)
                    this.$set(obj, 'changeState', false)
                    this.$set(obj, 'switchLoading', false)
                    return obj
                  })
          }).reduce((a,b)=>{
            return a.concat(b)
          })
          this.data = arr
          // console.log(arr)
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      deep: true
    }
  },
  methods:{
    // 展开方法
    developChange(){
      const developSelect = JSON.stringify(this.packUp)
      this.developSelect = JSON.parse(developSelect)
    },
    // 收起方法
    packUpChange(){
      this.developSelect = []
    },
    // 开关显示判断
    switchShow(value){
      const type = value.type
      switch (type) {
        case 101:
        case 103:
        case 102:
        case 105:
        case 106:
          return true;
          break;
        default:
          return false;
          break;
      }
    },
    // 表格合并方法
    renderCells(text, data, key, index) {
      if (data.length < 1) {
        return 1
      }
      if (text === "" || text === null) {
        data[index].rowNum = 1
        return 1
      }
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1][key] && index % this.pagination.pageSize !== 0) {
        data[index].rowNum = 0
        return 0
      }
      let rowSpan = 1
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break
        }
        rowSpan++
      }
      data[index].rowNum = rowSpan
      return rowSpan
    },
    // 根据表格类型跳转对应的详情页
    goDetails(value) {
      let type = value.type
      switch (type) {
        // 门
        case 101:
        case 102:
        case 106:
          this.$router.push({
            path: '/homepage/door/doorDetails',
            query: { id: value.openId, pv: value.openStatus, doorId: value.id, siteId: this.$route.query.id }
          })
          break
        // 道闸
        case 105:
          this.$router.push({
            path: '/homepage/door/barrierGateDetails',
            query: { id: value.id, pv: value.openStatus, siteId: this.$route.query.id }
          })
          break
        // 闸机
        case 103:
          this.$router.push({
            path: '/homepage/door/gateMachineDetails',
            query: { id: value.id, siteId: this.$route.query.id }
          })
          break
        // 读卡器
        case 201:
        case 202:
        case 203:
        case 204:
        case 205:
        case 206:
        case 207:
        case 208:
          this.$router.push({
            path: '/homepage/door/cardReaderDetails',
            query: { id: value.id, siteId: this.$route.query.id }
          })
          break
        default:
          alert(this.$t('doorequipment.a13'))
          break
      }
    },
    // 门开关按钮操作方法
    onChange(checked,record) {
      console.log(checked,record)
      if (checked) {
        record.switchLoading = true
        let data = new FormData()
        data.append('id', record.openId)
        data.append('pv', record.openStatus == 0 ? 1 : 0)  //Modified by li_yj on 2022-3-16 改为与开关状态的相反状态
        doorSwitch(data)
          .then((res) => {
            console.log(res)
            let { errorCode } = res
            if (errorCode == "00") {
              record.switchLoading = false
              this.$message.success(this.$t('doorequipment.a14'))
              setTimeout(() => {
                record.changeState = false
              }, 5000)
            } else if (errorCode == "02") {
              record.changeState = false
              record.switchLoading = false
              this.$message.error(this.$t('doorequipment.a15'))
            } else {
              return false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        return false
      }
    },
    // 表格视频按钮操作
    getVideo(equipment) {
      this.vdialog = true
      this.camera = {
        clientId: this.$store.getters.clientId,
        sitegrId: this.$store.getters.sitegrId,
        siteId: this.$route.query.id,
        equipmentType: equipment.equipmentType,
        equipmentId: equipment.id,
        camera1: equipment.camera1,
        camera2: equipment.camera2,
        name: equipment.name
      }
    },
    // 卡片视频按钮点击回调函数
    hasVideoChange(data){
      this.vdialog = data.vdialog
      this.camera = data.camera
    }
  },
  components: {
    'my-doorcard': doorcard,
    'video-dialog': videoDialog
  }
}
</script>

<style scoped>
div /deep/.ant-collapse-content > .ant-collapse-content-box{
  padding: 0px;
  padding-top: 15px;
}
div /deep/.ant-collapse{
  border-left: 0px;
  border-right: 0px;
}
div /deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  padding: 8px 16px;
  padding-left: 40px;
}
ul,li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#equipment {
  width: 100%;
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(12, 12, 12, 0.85);
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  white-space: nowrap;
  overflow: hidden;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.main {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
  overflow: hidden;
}
.header-margin-right{
  margin-right: 20px;
}
.card-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.empty {
  width: 200px;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.type{
  display: flex;
  align-items: center;
}
.main-view{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>