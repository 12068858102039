<!--门禁模块--设备卡片组件-->
<template>
  <div id="doorcard" @click="goDetails">
    <div class="icon">
      <img :src="getImages" />
      <span>{{ cardContent.state }}</span>
    </div>
    <div class="title" :title="cardContent.name">
      <span>{{ cardContent.name }}</span>
    </div>
    <div class="opea">
      <div class="switch" @click.stop>
        <a-switch v-model="changeState" :default-checked="state" :disabled="disabled" v-if="!disabled" :loading="loading" @change="onChange"/>
      </div>
      <a-button v-if="cardContent.hasVideo" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent)"/>
    </div>
  </div>
</template>

<script>
import { doorSwitch } from '../../../api/door'

export default {
  name: 'doorcard',
  props: {
    cardContent: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      changeState: false,
      vdialog: false,
    };
  },
  methods: {
    getVideo(equipment) {
      const data = {
        vdialog: true,
        camera: {
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          equipmentType: equipment.equipmentType,
          equipmentId: equipment.id,
          camera1: equipment.camera1,
          camera2: equipment.camera2,
          name: equipment.name
        }
      }
      this.$emit('hasVideoChange', data )
    },
    // 根据卡片类型跳转对应的详情页
    goDetails() {
      let type = this.cardContent.type;
      switch (type) {
        // 门
        case 101:
        case 102:
        case 106:
          this.$router.push({
            path: "/homepage/door/doorDetails",
            query: {
              id: this.cardContent.openId,
              pv: this.cardContent.openStatus,
              doorId: this.cardContent.id,
              siteId: this.$route.query.id
            },
          })
          break
        // 道闸
        case 105:
          this.$router.push({
            path: "/homepage/door/barrierGateDetails",
            query: {
              id: this.cardContent.id,
              pv: this.cardContent.openStatus,
              siteId: this.$route.query.id
            }
          })
          break;
        // 闸机
        case 103:
          this.$router.push({
            path: "/homepage/door/gateMachineDetails",
            query: {
              id: this.cardContent.id,
              siteId: this.$route.query.id
            }
          })
          break;
        // 读卡器
        case 201:
        case 202:
        case 203:
        case 204:
        case 205:
        case 206:
        case 207:
        case 208:
          this.$router.push({
            path: "/homepage/door/cardReaderDetails",
            query: {
              id: this.cardContent.id,
              siteId: this.$route.query.id
            }
          })
          break;
        default:
          alert(this.$t('doorequipment.a13'))
          break;
      }
    },
    // 门开关按钮操作方法
    onChange(checked) {
      if (checked) {
        this.loading = true
        let data = new FormData()
        data.append("id", this.cardContent.openId)
        data.append("pv", this.cardContent.openStatus == 0 ? 1 : 0) //Modified by li_yj on 2022-3-16 改为与开关状态的相反状态
        // console.log("doorSwitch openStatus:",this.cardContent.openStatus)
        console.log("doorSwitch par:",data.get("pv"))
        doorSwitch(data)
          .then((res) => {
            console.log(res)
            let { errorCode } = res
            if (errorCode == '00') {
              this.loading = false;
              this.$message.success(this.$t('doorequipment.a14'))
              setTimeout(() => {
                this.changeState = false;
              }, 5000);
            } else if (errorCode == '02') {
              this.changeState = false
              this.loading = false
              this.$message.error(this.$t('doorequipment.a15'))
            } else {
              return false
            }
          })
          .catch((err) => {
            console.log(err)
          });
      } else {
        return false
      }
    },
  },
  computed: {
    // 根据类型禁用卡片的开关按钮
    disabled: function () {
      let type = this.cardContent.type;
      switch (type) {
        case 101:
        case 103:
        case 102:
        case 105:
        case 106:
          return false;
          break;
        default:
          return true;
          break;
      }
    },
    // 按钮根据后台返回状态值显示初始状态
    state: function () {
      if (this.cardContent.openStatus == 1) {
        return true;
      } else {
        return false;
      }
    },
    // 卡片图标根据对应iocnNO加载对应图标
      //change by pzj
      // equipmentType： 表示设备类别 100：门  200：读卡器
      // type ： 设备类型
      // iconNo :设备状态 0：正常  8：维护  2：故障

    getImages: function () {
      let type = this.cardContent.type;
      let equipmentType = this.cardContent.equipmentType;
      let iconNo = this.cardContent.iconNo.toString();

      if(this.cardContent.iconNo!=0 && this.cardContent.iconNo!=8 && this.cardContent.iconNo!=2 )
          iconNo="0";

      return require("../../../../public/images/doorIconNo/"+equipmentType+"/"+type+"/"+ iconNo + ".png");
    },
  }
}
</script>

<style scoped>
#doorcard {
  width: 200px;
  height: 110px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 200ms;
}
#doorcard:hover {
  border: 1px solid #7682ce;
}
.title {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.switch {
  width: 55px;
  padding-left: 10px;
}
.opea {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.icon{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}
</style>