<template>
    <div id="equipmentVideo"  > 
        <a-modal v-model="visible" :width="width" :title="title" @ok="handleOk">
            <div v-loading="vcloading" :element-loading-text="$t('alarm.a0')">
                <a-row>
                    <a-col v-if="brand==12" :span="16">
                        <div id="divSimplePlugin" :style="{width:vwidth+'px',height:vhigh+'px'}"></div>
                    </a-col>
                    <a-col v-else-if="brand==11" :span="16">
                         <player ref="player" :style="{width:vwidth+'px',height:vhigh+'px'}" videoUrl="" fluent @screenshot="shot"  @destroy="destroy"/>
                    </a-col>
                    <a-col v-else :span="16">
                        <a-empty>
                            <span slot="description"> {{videoBrandTxt}}</span>
                        </a-empty>
                    </a-col>
                    <a-col :span="8" style="padding-left: 10px;">
                        <div class="header-condition ">
                            <span>{{ $t('video.viddata006')}}</span>
                            <span> {{camera==null?"":camera.name}}</span>
                        </div>
                        <div class="header-condition ">
                            <span>{{ $t('energy.enedata345')}}</span>
                            <a-select v-model="cequipmentId" style="width: 200px">
                                <a-select-option v-for="item in cequipments"
                                    :key="item.no" >
                                    {{item.text}}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="header-condition ">
                            <span>{{ $t('energy.enedata343')}}</span>
                            <a-select v-model="signalId" style="width: 200px" @change="signalChange">
                                <a-select-option v-for="item in signals"
                                    :key="item.signalId" >
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="header-condition ">
                            <span>{{ $t('energy.enedata151')}}</span>
                            <a-select v-model="operaId" style="width: 200px">
                                <a-select-option v-for="item in operations"
                                    :key="item.no" >
                                    {{item.text}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </a-modal>
    </div>
</template>
<script>
import {getEquipmentVideoCamera,proxySave} from '../../api/video'
import player from '../../components/video/jessibuca.vue'
export default {
    name:'equipmentVideo',
    props:{
        bvisible:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            vcloading:false,
            visible: this.bvisible,
            camera:null,
            cameraNum:0,
            title:'',
            brand:0,
            videoBrandTxt:this.$t('video.viddata061'),
            width:720,
            vwidth:400,
            vhigh:360,
            cequipments:[],
            cequipment:null,
            cequipmentId:0,
            signal:null,
            signalId:0,
            signals:[],
            operation:null,
            operaId:0,
            operations:[],
            operationMap:new Map(),
            signalMap:new Map(),
            params:null,

        };
    },
    mounted(){
        console.log('mounted');
        
    },
    destroyed(){
        if(this.camera!=null && this.brand==12){
            this.videoStop();
            this.videoLogout(this.camera);
        }
    },
    watch:{
        "$parent.vdialog":{
             handler(val){
                if(val){
                    this.visible = val;
                    this.vcloading = true;
                    console.log(this.$parent.camera);
                    if(this.$parent.camera!=null){
                        this.params= this.$parent.camera;
                        this.title = this.$parent.camera.name;
                        this.getCameraInfo(this.$parent.camera);
                    }
                    
                }
            },
            deep:true,
            immediate:true,
        },
        "visible":{
            handler(val){
                if(!val){
                    this.$parent.vdialog =  val;
                    if(this.camera!=null){
                        this.videoStop();
                        this.videoLogout(this.camera);

                        this.camera=null;
                        this.cameraNum=0;
                        this.title='';
                        this.brand=0;

                        this.cequipments=[];
                        this.cequipment=null;
                        this.cequipmentId=0;
                        this.signal=null;
                        this.signalId=0;
                        this.signals=[];
                        this.operation=null;
                        this.operaId=0;
                        this.operations=[];
                        this.operationMap=new Map();
                        this.signalMap=new Map();
                        this.params= null;
                    }
                }
            },
            deep:true,
            immediate:true,
        },    
    },
    methods: {
        videoStop(){
            if(this.brand===11){

            }else  if(this.brand===12){
                WebVideoCtrl.I_Stop();
            }
        },
        videoInit(width,high,pluginId){
            // 检查插件是否已经安装过
            let iRet = WebVideoCtrl.I_CheckPluginInstall();
            if (-1 == iRet) {
                this.$message.error(this.$t('viddata062'));
                return;
            }

            let that = this;
            // 初始化插件参数及插入插件
            WebVideoCtrl.I_InitPlugin(width, high, {
                bWndFull: true,//是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
                iWndowType: 1,
                cbInitPluginComplete: function () {
                    WebVideoCtrl.I_InsertOBJECTPlugin(pluginId);                 
                    if(that.camera!=null){
                        that.videoLogin();
                    }
                }
            });
        },
        videoLogin(){
            // 登录设备
            let that = this;

            WebVideoCtrl.I_Login(this.camera.recorderIpAddress, 1, this.camera.recorderConnectPort, this.camera.monitoringUserId, this.camera.monitoringUserPassword, {
                success: function (xmlDoc) {
                    console.log('VideoLogin',"Success");
                    // 开始预览
                    that.videoRealPlay(that.camera);
                    
                }
            });
        },
        videoLogout(camera){
            let iRet = 0;
            if(this.brand===11){
                this.destroy();
            }else  if(this.brand===12){
                camera = camera?camera:this.camera;
                let szDeviceIdentify = camera.recorderIpAddress + "_" + camera.recorderConnectPort;
                iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
                console.log('Video logout',iRet);
            }
            return iRet;
        },
        videoRealPlay(camera){
            camera = camera?camera:this.camera;
            let szDeviceIdentify = camera.recorderIpAddress + "_" + camera.recorderConnectPort;
            WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                iStreamType: 1,
                iChannelID: camera.recorderChannelNo,
                bZeroChannel: false,
                success:function(){
                        console.log('videoRealPlay success');
                },
                error:function(){
                        console.log('videoRealPlay error');
                }

            });
            // setTimeout(function () {
               
            // }, 500);
        },
        signalChange(e){
            console.log('signalChange1',e);
            let s = this.signalMap.get(parseInt(e));
            console.log('signalChange2',s,this.operationMap,s.memberType);
            this.operations = this.operationMap.get(parseInt(s.memberType));
            console.log('signalChange3', this.operations);
            this.operation = this.operations[0];
            this.operaId = this.operations[0].no;
            this.signal = s;
        },
        getCameraInfo(param){
            this.vcloading = true;
            getEquipmentVideoCamera(param)
                .then((res) => {
                console.log("getEquipmentVideoCamera",res);
                if(res.errorCode==='00'){
                    this.cameraNum = res.data.cameraNum;
                     console.log("equipments",res.data.equipments,res.data.equipments[0],res.data.equipments[0].no);
                    this.cequipments = res.data.equipments;
                    this.cequipment = res.data.equipments[0];
                    this.cequipmentId = res.data.equipments[0].no;

                    this.signals = res.data.signals;
                    this.signal = res.data.signals[0];
                    this.signalId = this.signal.signalId;

                    for (let i = 0; i < res.data.signals.length; i++) {
                        let sele = res.data.signals[i];
                        this.signalMap.set(sele.signalId,sele);
                    }


                    for (let i = 0; i < res.data.operations.length; i++) {

                        let oele = res.data.operations[i];
                        this.operationMap.set(parseInt(oele.no),oele.children);

                        if(parseInt(oele.no) === this.signal.memberType){
                            this.operations = oele.children;
                            this.operation = this.operations[0];
                            this.operaId = this.operations[0].no;
                        }
                    }

                    if(res.data.cameraNum>0){
                        console.log('camera',res.data.camera1);
                        if(res.data.camera1!=null){
                            this.camera = res.data.camera1;
                            // this.camera = {
                            //     "id": 838,
                            //     "name": "饭堂卸货区",
                            //     "monitoringUserId": "admin",
                            //     "monitoringUserPassword": "admin123",
                            //     "downloadPort": 80,
                            //     "recorderConnectPort": null,
                            //     "recorderChannelNo": 1,
                            //     "recorderIpAddress": "10.98.4.162",
                            //     "cameraType": 11,
                            //     "key": 1
                            // }
                            this.brand = this.camera.cameraType;
                            if(res.data.camera1.cameraType === 12 ){
                                this.videoInit(this.vwidth,this.vhigh,'divSimplePlugin');
                            }else if(res.data.camera1.cameraType === 11 ){
                                this.proxyVideoPlay(this.camera);
                            }else{
                                this.vcloading = false;
                            }
                        }else{
                            this.vcloading = false;
                        }
                    }else{
                            this.vcloading = false;
                        }
                }else{
                    this.vcloading = false;
                }
            }).catch((err) => {
                console.log(err);
                this.vcloading = false;
            });
            // return {
            //     cameraType: 12,
            //     downloadPort: 8000,
            //     id: 51,
            //     key: 1,
            //     monitoringUserId: "admin",
            //     monitoringUserPassword: "123456asd",
            //     name: "南大门外",
            //     recorderChannelNo: 10,
            //     recorderConnectPort: 80,
            //     recorderIpAddress: "10.98.35.233",
            // }
        },
        handleOk(e) {
             let param = this.params;
             param.action = 1;
             param.operaId = this.operaId;
             param.memberType = this.signal.memberType;
             param.signalId = this.signalId;
             param.signalType = this.signalType;
             console.log('handleOk',param);
             this.$confirm({
                // title: "确定要进行设定内容注册吗?",
                title:this.$t("energy.enedata184"),
                centered: true,
                onOk: () => {
                    this.vcloading = true;
                    getEquipmentVideoCamera(param)
                        .then((res) => {
                            console.log("SetEquipmentVideoCamera res",res);
                            if(res.errorCode==='00' || res.errorCode==='01'){
                                this.$message.info(res.msg);
                            }else{
                                this.$message.error(res.msg);
                            }
                            this.vcloading = false;
                    }).catch((err) => {
                        console.log(err);
                        this.vcloading = false;
                    });
                }
            });
        },
        shot(e) {
            // console.log(e)
            // send({code:'image',data:e})
            var base64ToBlob = function (code) {
              let parts = code.split(';base64,');
              let contentType = parts[0].split(':')[1];
              let raw = window.atob(parts[1]);
              let rawLength = raw.length;
              let uInt8Array = new Uint8Array(rawLength);
              for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
              }
              return new Blob([uInt8Array], {
                type: contentType
              });
            };
            let aLink = document.createElement('a');
            let blob = base64ToBlob(e); //new Blob([content]);
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            aLink.download = '截图';
            aLink.href = URL.createObjectURL(blob);
            aLink.click();
        },
        destroy() {
            if(this.brand===11){
                this.$refs["player"].destroy(); 
            }
        },
        proxyVideoPlay(record){
            setTimeout(() => {
                this.$refs["player"].destroy(); 
                this.vcloading = true;
                
                proxySave(this.brand,record)
                    .then((res) => {
                    if(res.code===0){ 
                        this.$refs["player"].play("ws://10.98.234.210:80/" + this.brand + "/" + record.id + ".live.flv");
                    }
                    this.vcloading = false;
                }).catch((err) => {
                    this.$message.error('摄像头播放失败，请检查摄像头是否异常。');
                    this.vcloading = false;
                });
            }, 100);
        },
    },
    components: {
        player
    },
};
</script>
<style scoped>
.header-condition {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.header-condition button{
  margin-bottom: 5px;
}
.header-condition span{
  margin-bottom: 5px;
}
</style>